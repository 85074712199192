/*! source instant.page v5.2.0 - (C) 2019-2024 Alexandre Dieulot - https://instant.page/license */

'use strict';
(function ($) {

	T.Utils = T.Utils || {};

	T.Utils.PerfPrefetch = {
		_chromiumMajorVersionInUserAgent: null,
		_speculationRulesType: 'none',
		_delayOnHover: 65,
		_lastTouchstartEvent: null,
		_mouseoverTimer: null,
		_preloadedList: new Set(),
		isNavigatorConnectionAdequate: false,
		_nonce: undefined,

		init: function init() {
			const $scriptElem = $('#perf');

			if (!this.hasSupport()) {
				return;
			}

			this._nonce = $scriptElem.attr('data-config');
			$scriptElem.attr('data-config', undefined);

			this.setSpeculationType();
			this.addEventListeners();
		},

		hasSupport: function hasSupport() {
			const isNavigatorConnectionSaveDataEnabled = navigator.connection && navigator.connection.saveData;
			const isNavigatorConnectionLike2g = navigator.connection && navigator.connection.effectiveType && navigator.connection.effectiveType.includes('2g');
			this.isNavigatorConnectionAdequate = !isNavigatorConnectionSaveDataEnabled && !isNavigatorConnectionLike2g;

			if (!this.isNavigatorConnectionAdequate) {
				return false;
			}

			const supportCheckRelList = document.createElement('link').relList;
			const isSupported = supportCheckRelList.supports('prefetch')
				&& supportCheckRelList.supports('modulepreload');

			if (!isSupported) {
				return false;
			}

			// The `Vary: Accept` header when received in Chromium 79–109 makes prefetches
			// unusable, as Chromium used to send a different `Accept` header.
			const chromiumUserAgentIndex = navigator.userAgent.indexOf('Chrome/');
			if (chromiumUserAgentIndex > -1) {
				this._chromiumMajorVersionInUserAgent = parseInt(navigator.userAgent.substring(chromiumUserAgentIndex + 'Chrome/'.length));
			}
			return !(this._chromiumMajorVersionInUserAgent && this._chromiumMajorVersionInUserAgent < 110);
		},


		setSpeculationType: function setSpeculationType() {
			if (HTMLScriptElement.supports && HTMLScriptElement.supports('speculationrules')) {
				this._speculationRulesType = 'prefetch';
			} else {
				this._speculationRulesType = 'none';
			}
		},

		addEventListeners: function addEventListeners() {
			const eventListenersOptions = {
				capture: true,
				passive: true,
			};

			document.addEventListener('touchstart', this.touchstartListener.bind(this), eventListenersOptions);
			document.addEventListener('mouseover', this.mouseoverListener.bind(this), eventListenersOptions);
		},

		touchstartListener: function touchstartListener(event) {
			this._lastTouchstartEvent = event;

			const anchorElement = event.target.closest('a');

			if (!this.isPreloadable(anchorElement)) {
				return;
			}

			this.preload(anchorElement.href, 'high');
		},

		mouseoverListener: function mouseoverListener(event) {
			if (this.isEventLikelyTriggeredByTouch(event)) {
				return;
			}

			if (!('closest' in event.target)) {
				return;
			}
			const anchorElement = event.target.closest('a');

			if (!this.isPreloadable(anchorElement)) {
				return;
			}

			anchorElement.addEventListener('mouseout', this.mouseoutListener, { passive: true });

			this._mouseoverTimer = setTimeout(() => {
				this.preload(anchorElement.href, 'high');
				this._mouseoverTimer = null;
			}, this._delayOnHover);
		},

		mouseoutListener: function mouseoutListener(event) {
			if (event.relatedTarget && event.target.closest('a') === event.relatedTarget.closest('a')) {
				return;
			}

			if (this._mouseoverTimer) {
				clearTimeout(this._mouseoverTimer);
				this._mouseoverTimer = null;
			}
		},

		isEventLikelyTriggeredByTouch: function isEventLikelyTriggeredByTouch(event) {
			if (!this._lastTouchstartEvent || !event) {
				return false;
			}

			if (event.target !== this._lastTouchstartEvent.target) {
				return false;
			}

			const now = event.timeStamp;
			const durationBetweenLastTouchstartAndNow = now - this._lastTouchstartEvent.timeStamp;

			const MAX_DURATION_TO_BE_CONSIDERED_TRIGGERED_BY_TOUCHSTART = 2500;

			return durationBetweenLastTouchstartAndNow < MAX_DURATION_TO_BE_CONSIDERED_TRIGGERED_BY_TOUCHSTART;
		},

		isPreloadable: function isPreloadable(anchorElement) {
			if (!anchorElement || !anchorElement.href) {
				return false;
			}

			if (anchorElement.origin !== location.origin) {
				return false;
			}

			if (!['http:', 'https:'].includes(anchorElement.protocol)) {
				return false;
			}

			return !(anchorElement.protocol === 'http:' && location.protocol === 'https:');
		},


		preload: function preload(url, fetchPriority = 'auto') {
			if (this._preloadedList.has(url)) {
				return;
			}
			if (this._speculationRulesType !== 'none') {
				this.preloadUsingSpeculationRules(url);
			} else {
				this.preloadUsingLinkElement(url, fetchPriority);
			}


			this._preloadedList.add(url);
		},

		preloadUsingSpeculationRules: function preloadUsingSpeculationRules(url) {
			const scriptElement = document.createElement('script');
			scriptElement.setAttribute('nonce', this._nonce);
			scriptElement.type = 'speculationrules';

			scriptElement.textContent = JSON.stringify({
				[this._speculationRulesType]: [{
					source: 'list',
					urls: [url]
				}]
			});
			document.head.appendChild(scriptElement);
		},

		preloadUsingLinkElement: function preloadUsingLinkElement(url, fetchPriority = 'auto') {
			const linkElement = document.createElement('link');
			linkElement.rel = 'prefetch';
			linkElement.href = url;
			linkElement.setAttribute('nonce', this._nonce);
			linkElement.fetchPriority = fetchPriority;
			linkElement.as = 'document';
			document.head.appendChild(linkElement);
		},

		simplePreload: function simplePreload(url, as = 'script') {
			if (url && url.length > 0) {
				const preloadLink = document.createElement('link');
				preloadLink.rel = 'preload';
				preloadLink.as = as;
				preloadLink.href = url;
				preloadLink.fetchPriority = "high";
				document.head.appendChild(preloadLink);
			}
		}
	};

	T.Utils.PerfPrefetch.init();
})(jQuery);
